import EmberRouter from '@ember/routing/router';
import config from 'client-app-omnivise-web/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('auth', function () {
    this.route('view', { path: '/v/:view_id' });
  });
  this.route('login');
  this.route('licenses');

  this.route('info', function () {
    this.route('terms');
    this.route('privacy');
    this.route('corporate');
    this.route('cookies');
  });
});
