export default {
  "root": "_root_8pzt4v",
  "notifications-list": "_notifications-list_8pzt4v",
  "notifications-launcher-list-style": "_notifications-launcher-list-style_8pzt4v",
  "notif-button": "_notif-button_8pzt4v",
  "notifications-container-empty": "_notifications-container-empty_8pzt4v",
  "notifications-nav-tabs": "_notifications-nav-tabs_8pzt4v",
  "active": "_active_8pzt4v",
  "scroll-observer": "_scroll-observer_8pzt4v"
};
