import Controller from '@ember/controller';

export default class AuthView extends Controller {
  queryParams = ['state', 'avc'];

  // TODO: by this call we will know if EOS view
  get isOESView() {
    return this.model.internalView.id.startsWith('--oes--');
  }
}
