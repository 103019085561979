import ApplicationAdapter from './application';

export default class NotificationAlert extends ApplicationAdapter {
  namespace = 'api/v3';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'notification-alert': NotificationAlert;
  }
}
