import Model, { attr } from '@ember-data/model';

export default class extends Model {
  @attr('string')
  description;

  @attr('string')
  timeStamp;

  @attr('string')
  notificationType;

  @attr('boolean')
  isRead;

  @attr('string')
  applicationTypeDescription;

  @attr('string')
  unitName;

  @attr('string')
  title;
}
