import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (feature-flag \"oes-tickets\")}}\n  <div>\n    <svg\n      class=\"ticket-icon\"\n      xmlns=\"http://www.w3.org/2000/svg\"\n      width=\"24\"\n      height=\"24\"\n      viewBox=\"0 0 24 24\"\n      fill=\"none\"\n      stroke=\"purple\"\n      stroke-width=\"2\"\n      stroke-linecap=\"round\"\n      stroke-linejoin=\"round\"\n    >\n      <path d=\"M15 5v2\"></path>\n      <path d=\"M15 11v2\"></path>\n      <path d=\"M15 17v2\"></path>\n      <rect x=\"3\" y=\"4\" width=\"18\" height=\"16\" rx=\"2\"></rect>\n      <path d=\"M3 8h18\"></path>\n      <path d=\"M3 16h18\"></path>\n    </svg>\n  </div>\n{{/if}}", {"contents":"{{#if (feature-flag \"oes-tickets\")}}\n  <div>\n    <svg\n      class=\"ticket-icon\"\n      xmlns=\"http://www.w3.org/2000/svg\"\n      width=\"24\"\n      height=\"24\"\n      viewBox=\"0 0 24 24\"\n      fill=\"none\"\n      stroke=\"purple\"\n      stroke-width=\"2\"\n      stroke-linecap=\"round\"\n      stroke-linejoin=\"round\"\n    >\n      <path d=\"M15 5v2\"></path>\n      <path d=\"M15 11v2\"></path>\n      <path d=\"M15 17v2\"></path>\n      <rect x=\"3\" y=\"4\" width=\"18\" height=\"16\" rx=\"2\"></rect>\n      <path d=\"M3 8h18\"></path>\n      <path d=\"M3 16h18\"></path>\n    </svg>\n  </div>\n{{/if}}","moduleName":"client-app-omnivise-web/components/header/oes/tickets.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/header/oes/tickets.hbs"}});
import Component from '@glimmer/component';

export interface HeaderOesTicketsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: []
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class HeaderOesTickets extends Component<HeaderOesTicketsSignature> {}
